angular.module("APP", [
	'DS.ngJS'
	]);

angular.module("APP").run(function($rootScope, $location, $http, $anchorScroll) {

	$rootScope.data = {};
	$rootScope.validate = false;

	$rootScope.go_form = function() {
		console.log('asdad');
		$location.hash('footer');
      	$anchorScroll();
	}

	$rootScope.sendForm = function() {

		$rootScope.validate = true;

		if(!$rootScope.Form.$valid){
			return false;
		}

		console.log("$rootScope.data", $rootScope.data);
		$http.post('https://api.bancodosimoveis.com.br/v1/public/forms/parceiro?token=bdi', $rootScope.data)
			.then(function(res) {
				var data = res.data;

				if(data.success){
					alert('Seu pedido foi realizado com sucesso, entraremos em contato com você em breve!');
					$rootScope.data = {};
				}else{
					alert('Erro ao tentar conectar com servidor!');
				}
			});
	}
// 	nome: Alberto Ammar
// email: albertoammarw@gmail.com
// phone: (11) 99989-4641

	// https://api.bancodosimoveis.com.br/v1/public/forms/parceiro?token=bdi
});